@import "../external/stylesheets/normalize.scss";
@import "../external/stylesheets/skeleton.scss";


.buttons > button {
    margin-right: 2ch;  
}

.chessboard {
    width: 640px;
    height: 640px;
    margin: 2em 0 2em 0;
    border: 25px solid #333;
}
.black {
    float: left;
    width: 80px;
    height: 80px;
    background-color: #999;
      font-size:50px;
    text-align:center;
    display: table-cell;
    vertical-align:middle;
    line-height: 1.58em;
}

.black.square:hover{
    background-color: darken(#999, 3%)
}
.white.square:hover{
    background-color: darken(#ccc, 3%)
}

.square.selected {
    background-color: red;
}
.square.selected:hover {
    background-color: darken(red, 3%)
}

.white {
    float: left;
    width: 80px;
    height: 80px;
    background-color: #ccc;
    font-size:50px;
    text-align:center;
    display: table-cell;
    vertical-align:middle;
    line-height: 1.58em;
}

.white-piece {
    color: white;
}

.black-piece {
    color: black;
}

.page {
    margin-top: 2em;
}

.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }

.modal.active {
    display: block;
}

/* Modal Content/Box */
.modal-content {
    background-color: #fefefe;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 80%; /* Could be more or less, depending on screen size */
  }
  
  /* The Close Button */
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }